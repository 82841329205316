// Splash Component that cover the screen

import { FC, useEffect, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import splashImg from 'src/assets/image/splash01.png';
import SplashLogo from 'src/assets/logo/splash.png';

interface IProps {
  isLoading: boolean;
}

const svgVariants = {
  start: { pathLength: 0, fill: 'rgba(255, 255, 255, 0)' },
  end: {
    pathLength: 1,
    fill: 'rgba(255, 255, 255, 1)',
  },
};

const Splash: FC<IProps> = ({ isLoading }: IProps) => {
  const [localLoading, setLocalLoading] = useState<boolean>(true);

  // 로딩이 너무 빠르면 1초 있다가 Splash를 없앤다.
  useEffect(() => {
    const timeoutId = setTimeout(() => setLocalLoading(false), 1400);

    return () => clearTimeout(timeoutId);
  }, []);

  // 둘 모두 false일 때 Splash를 없앤다.
  const loading = isLoading && localLoading;

  return (
    <AnimatePresence>
      {loading && (
        <motion.div
          style={{
            backgroundImage: `url(${splashImg})`,
          }}
          className={`center-box fixed top-0 left-1/2 z-50 h-full w-full max-w-screen-md -translate-x-1/2 bg-cover bg-bottom`}
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="h-[300px]">
            <img src={SplashLogo} className="h-[130px]"></img>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Splash;

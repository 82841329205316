//* 프리드같이 배송사진을 꼭 봐야할 상조를 위한 모달
import { FC, useMemo } from 'react';

import { ShopOrderForAgencyAdmin } from '@shared/api/shopOrder/shopOrder.interface';
import Modal from '@shared/components/Modal';
import { fileBucketUrl } from '@shared/utils/fileUtils';
import { Image as AntImage } from 'antd';

interface Props {
  openId: string;
  shopOrder?: ShopOrderForAgencyAdmin;
}

export const DeliveredImageModal: FC<Props> = function DeliveredImageModal({
  openId,
  shopOrder,
}: Props) {
  const deliveredInfo = useMemo(() => {
    if (shopOrder) {
      return (
        <div className="grid grid-cols-auto-1fr gap-x-4 gap-y-1 rounded-md py-2 text-base font-bold">
          <p>보내는이</p>
          <p>{shopOrder?.bugoDetail?.senderPhrase}</p>
          <p>조의문구</p>
          <p>{shopOrder?.bugoDetail?.condolencePhrase}</p>
        </div>
      );
    }
  }, [shopOrder]);

  const imageRender = useMemo(() => {
    if (shopOrder?.deliveryDetail.deliveredImage) {
      return (
        <AntImage
          src={fileBucketUrl(shopOrder?.deliveryDetail.deliveredImage.url, 'w1080')}
          preview={{
            src: fileBucketUrl(shopOrder?.deliveryDetail.deliveredImage.url, 'raw'),
          }}
        ></AntImage>
      );
    } else {
      return <p>사진준비중입니다.</p>;
    }
  }, [shopOrder?.deliveryDetail?.deliveredImage]);

  return (
    <Modal openId={openId} className="space-y-4">
      <div className="center-box w-full  rounded-lg border theme-bg-8">
        {deliveredInfo}
      </div>
      <div className="center-box">{imageRender}</div>
    </Modal>
  );
};

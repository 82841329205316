import { FC } from 'react';

import { Icon } from '@shared/components/icons';
import { useAuth } from '@shared/state/hooks/useAuth';
import { getAgencyWorkerTeamName } from '@shared/utils/agencyWorkerUtils';
import { phoneNumberStringFormator } from '@shared/utils/phoneNumberStringFormator';
import { Link } from 'react-router-dom';
import bottomBanner from 'src/assets/banner/bottomBanner.jpg';

import PreferCemeteryEditor from '../containers/PreferCemeteryEditor/PreferCemeteryEditor';
import { useTitleHook } from '../hooks/useTitleHook';

const AgencyWorkerMyPage: FC = () => {
  useTitleHook('마이페이지');
  const { userProfile } = useAuth();

  const { Phone } = Icon();

  return (
    <div className="space-y-3 py-3 text-sm">
      {/* 환영합니다~~~ */}
      <div className="center-box py-2 theme-bg-1">
        <p className="center-box text-base font-medium">
          <strong className="theme-text-main">{userProfile?.info.name}</strong>님
          환영합니다
        </p>
      </div>
      {/* 계정정보 */}
      <div className="space-y-2 py-2 px-4 theme-bg-1">
        <h5 className="theme-text-8">계정정보</h5>
        <div className="flex items-center">
          <h5 className="flex-1 theme-text-8">아이디</h5>
          <div className="flex flex-[3] items-center justify-between text-xs">
            <p className="font-medium">{userProfile?.username}</p>
            <Link
              to="/user/change-password"
              className="rounded-sm border py-2 px-2 theme-text-8 theme-bg-9 theme-border-3"
            >
              비밀번호 변경
            </Link>
          </div>
        </div>
      </div>
      {/* 기본정보 */}
      <div className="space-y-2 py-2 px-4 theme-bg-1">
        <h5 className="theme-text-8">기본정보</h5>
        <div className="grid grid-cols-2">
          <p className="font-medium">{userProfile?.info.name}</p>
          <div className="flex items-center gap-1">
            <Phone className="wh-4" />
            <p className="font-medium">
              {phoneNumberStringFormator(userProfile?.info.phoneNumber)}
            </p>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <p className="space-x-1 font-medium">
            <span>{userProfile && getAgencyWorkerTeamName(userProfile)}</span>
            <span>소속</span>
          </p>
        </div>
      </div>
      {/* 편리정보 */}
      <div className="space-y-2 py-2 px-4 theme-bg-1">
        <h5 className="theme-text-8">편리정보</h5>
        {/* 장지 관리 */}
        <div className="space-y-2">
          <h5 className="font-medium">자주쓰는 장지 관리</h5>
          <PreferCemeteryEditor />
        </div>
      </div>
      {/* 바텀 배너 */}
      <div>
        <a href="https://www.preedlife.com/mobile/main">
          <img src={bottomBanner} />
        </a>
      </div>
    </div>
  );
};

export default AgencyWorkerMyPage;

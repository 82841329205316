import { FC, useEffect } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/icons';
import { SidePopup } from '@shared/components/SidePopup';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import topLogo from 'src/assets/logo/topLogo.png';

import {
  bugoNavBackUrlAtom,
  bugoNavBarUseAtom,
  bugoNavBarUseNavLeftAtom,
  bugoNavRightPhoneNumberAtom,
  bugoNavRightTextAtom,
  bugoNavTitleAtom,
} from '../state';

export const BugoNavBar: FC = function BugoTopNavBar() {
  const { Back, ChevronRight, EmptyUser, User, Menu } = Icon();
  const openId = 'bugoNavSidePopup';
  const setSideMenuOpen = useSetRecoilState(simpleOpenAtomFamily(openId));

  const location = useLocation();
  const pathName = location.pathname;
  const navigate = useNavigate();
  const title = useRecoilValue(bugoNavTitleAtom);
  const rightText = useRecoilValue(bugoNavRightTextAtom);
  const rightPhoneNumber = useRecoilValue(bugoNavRightPhoneNumberAtom);
  const navBarUse = useRecoilValue(bugoNavBarUseAtom);
  const navLeftUse = useRecoilValue(bugoNavBarUseNavLeftAtom);
  const backUrl = useRecoilValue(bugoNavBackUrlAtom);

  const {
    userProfile,
    userBugoBrand,
    apiLogout,
    isAuthenticated,
    isAdmin,
    isBugoAgencyAdmin,
    isBugoAgencyWorker,
    isBugoAgencyBranchAdmin,
    bugoBrandId,
  } = useAuth();

  useEffect(() => {
    return setSideMenuOpen(false);
  }, [setSideMenuOpen]);

  const menuButton = (text: string, to: string, willBeUpdated?: boolean) => {
    if (willBeUpdated) {
      return (
        <div
          className="flex h-10 w-full items-center justify-between pl-1"
          onClick={() => {
            alert('업데이트 예정 기능입니다.');
          }}
        >
          <p className="bugo-h2">{text}</p>
          <ChevronRight />
        </div>
      );
    } else {
      return (
        <Link
          to={to}
          className="flex h-10 w-full items-center justify-between pl-1"
          onClick={() => {
            setSideMenuOpen(false);
          }}
        >
          <p className="bugo-h2">{text}</p>
          <ChevronRight />
        </Link>
      );
    }
  };

  const bugoSideMenu = (login: boolean) => {
    const sharedMenu = (
      <>
        {(isBugoAgencyAdmin() || isAdmin() || isBugoAgencyBranchAdmin()) && (
          <div>
            <div className="px-4 py-0.5">
              <p className="text-xs font-bold leading-5 theme-text-8">관리자통계</p>
            </div>
            <div className="space-y-2 px-3 py-2 theme-bg-1">
              {menuButton('부고등록 현황', `/bugo-brand/${bugoBrandId}/bugo-stat`)}
              {menuButton('화환판매 현황', `/bugo-brand/${bugoBrandId}/flower-stat`)}
            </div>
          </div>
        )}
        {(isBugoAgencyAdmin() || isAdmin()) && (
          <div>
            <div className="px-4 py-0.5">
              <p className="text-xs font-bold leading-5 theme-text-8">공지사항</p>
            </div>
            <div className="space-y-2 px-3 py-2 theme-bg-1">
              {menuButton('공지사항 등록', '/bugo-brand/announcement/add')}
              {menuButton('공지사항 관리', '/bugo-brand/announcement/manage')}
            </div>
          </div>
        )}
        {(isBugoAgencyAdmin() || isAdmin()) && (
          <div>
            <div className="px-4 py-0.5">
              <p className="text-xs font-bold leading-5 theme-text-8">상조관리</p>
            </div>
            <div className="space-y-2 px-3 py-2 theme-bg-1">
              {menuButton('기본정보관리', `/bugo-brand/${bugoBrandId}/manage`, true)}
              {menuButton('지도사 등록현황', `/bugo-brand/${bugoBrandId}/agency-workers`)}
              {menuButton('어셋관리', `/bugo-brand/${bugoBrandId}/asset`)}
            </div>
          </div>
        )}
        {(isBugoAgencyWorker() || isAdmin()) && (
          <div>
            <div className="px-4 py-0.5">
              <p className="text-xs font-bold leading-5 theme-text-8">마이페이지</p>
            </div>
            <div className="space-y-2 px-3 py-2 theme-bg-1">
              {menuButton('계정관리', '/user/mypage')}
              {userBugoBrand?.bugoAgency?.shareRebateWithWorker &&
                menuButton('정산금관리', '/user/account-point')}
            </div>
          </div>
        )}
        {(isBugoAgencyWorker() || isAdmin()) && (
          <div>
            <div className="px-4 py-0.5">
              <p className="text-xs font-bold leading-5 theme-text-8">부고메뉴</p>
            </div>
            <div className="space-y-2 px-3 py-2 theme-bg-1">
              {menuButton('부고 등록', '/fevent/add')}
              {menuButton('부고 관리', '/fevent/manage')}
            </div>
          </div>
        )}
        {(isBugoAgencyWorker() || isAdmin()) && (
          <div>
            <div className="px-4 py-0.5">
              <p className="text-xs font-bold leading-5 theme-text-8">화환메뉴</p>
            </div>
            <div className="space-y-2 px-3 py-2 theme-bg-1">
              {menuButton('화환 보내기', '/flower-store')}
              {/* //TODO: 종합버전에서 살리기 */}
              {/* {menuButton('답례품 보내기', '/flower-store')} */}
            </div>
          </div>
        )}

        <div>
          <div className="px-4 py-0.5">
            <p className="text-xs font-bold leading-5 theme-text-8">고객센터</p>
          </div>
          <div className="space-y-2 px-3 py-2 theme-bg-1">
            {menuButton('자주 묻는 질문', '/faq')}
            {menuButton('문의하기', '/enquiry')}
            {menuButton('내 문의내역', '/my-enquiries')}
          </div>
        </div>
      </>
    );

    if (login) {
      return (
        <div className="relative min-h-screen w-64 theme-text-1 theme-bg-6">
          <div className="absolute right-0 top-0"></div>
          <div className="flex items-center justify-between pl-3 pr-2 pt-10 pb-4 theme-bg-1">
            <div className="flex items-center gap-2">
              <User />
              <p className="bugo-h1">{userProfile?.info?.name}님</p>
            </div>
            <Button
              className="button-fold text-xs leading-5 theme-text-8 theme-bg-1 theme-border-1"
              onClick={() => {
                apiLogout();
              }}
            >
              로그아웃
            </Button>{' '}
          </div>
          {/* <div>
            <div className="px-4 py-0.5">
              <p className="text-xs font-bold leading-5 theme-text-8">마이페이지</p>
            </div>
            <div className="space-y-2 px-3 py-2 theme-bg-1">
              {menuButton('적립금 관리', '/fevent/add')}
              {menuButton('나를 추천한 회원', '/fevent/manage')}
            </div>
          </div> */}
          {sharedMenu}
        </div>
      );
    } else {
      return (
        <div className="min-h-screen w-64 theme-text-1 theme-bg-6">
          <Link to="/login">
            <div className="flex items-center justify-between pl-3 pr-2 pt-10 pb-4 theme-bg-1">
              <div className="flex items-center gap-2">
                <EmptyUser />
                <p className="bugo-h1">로그인</p>
              </div>
              <ChevronRight />
            </div>
          </Link>
          {sharedMenu}
        </div>
      );
    }
  };

  const navLeft = () => {
    if (pathName === '/') {
      return (
        <Link to="/">
          <img src={topLogo} className="h-9"></img>
        </Link>
      );
    } else {
      return (
        <div className="flex items-center">
          {navLeftUse && (
            <button
              onClick={() => {
                backUrl ? navigate(backUrl) : navigate(-1);
              }}
              className="center-box wh-10"
            >
              <Back />
            </button>
          )}
          <p className="bugo-h1">{title}</p>
        </div>
      );
    }
  };

  const navRight = () => {
    if (rightText === '') {
      return (
        <div
          className="center-box wh-10 cursor-pointer"
          onClick={() => setSideMenuOpen(true)}
        >
          <Menu />
        </div>
      );
    } else {
      return (
        <div>
          <a href={`tel:${rightPhoneNumber}`}>
            <p className="bugo-h2 cursor-pointer theme-text-main">{rightText}</p>
          </a>
        </div>
      );
    }
  };

  if (pathName.endsWith('login') || pathName.endsWith('error') || !navBarUse) {
    return null;
  } else {
    return (
      <>
        <div className="fixed z-20 flex h-14 w-full max-w-inherit items-center justify-between px-4 theme-bg-2">
          {navLeft()}
          {navRight()}
        </div>
        <SidePopup openId={openId}>{bugoSideMenu(isAuthenticated)}</SidePopup>
      </>
    );
  }
};

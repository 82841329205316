import { FC } from 'react';

import { Coord } from '@shared/interfaces/map.interface';
import kakaoNaviLogo from 'src/assets/logo/kakao-navi-logo.png';
import naverNaviLogo from 'src/assets/logo/naver-navi-logo.png';
import tmapLogo from 'src/assets/logo/tmap-logo.png';

import Modal from './Modal';

interface Props {
  openId: string;
  coords: Coord;
  destinationName: string;
  publicTransport?: boolean;
}

const origin = window.location.origin;

const NavigatorsModal: FC<Props> = ({
  openId,
  coords,
  destinationName,
  publicTransport,
}: Props) => {
  const { x, y } = coords;

  const isAndroidOrSamsungDevice = navigator
    ? /android|galaxy|samsung/i.test(navigator.userAgent)
    : false;
  const isIphone = navigator ? /iphone/i.test(navigator.userAgent) : false;

  if (publicTransport) {
    return (
      <Modal id={openId} openId={openId} className="w-full" position="center">
        <div className="flex">
          {isIphone ? (
            <button
              className="flex flex-1 flex-col items-center space-y-2"
              onClick={() => {
                const appStoreLink = 'http://itunes.apple.com/app/id311867728?mt=8';
                window.location.href = `nmap://route/public/navigation?dlat=${y}&dlng=${x}&dname=${destinationName}&appname=${origin}`;
                setTimeout(() => {
                  window.location.href = appStoreLink;
                }, 1000);
              }}
            >
              <img src={naverNaviLogo} className="w-14" />
              <h4 className="text-sm font-bold">네이버</h4>
            </button>
          ) : (
            <a
              // https://guide.ncloud-docs.com/docs/naveropenapiv3-maps-url-scheme-url-scheme
              href={
                isAndroidOrSamsungDevice
                  ? `intent://route/public/navigation?dlat=${y}&dlng=${x}&dname=${destinationName}&appname=${origin}#Intent;scheme=nmap;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.nhn.android.nmap;end`
                  : `nmap://route/public/navigation?dlat=${y}&dlng=${x}&dname=${destinationName}&appname=${origin}`
              }
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-1 flex-col items-center space-y-2"
            >
              <img src={naverNaviLogo} className="w-14" />
              <h4 className="text-sm font-bold">네이버</h4>
            </a>
          )}
          <a
            // https://apis.map.kakao.com/web/guide/#mapurl
            href={`https://map.kakao.com/link/to/${destinationName},${y},${x}`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-1 flex-col items-center space-y-2"
          >
            <img src={kakaoNaviLogo} className="w-14" />
            <h4 className="text-sm font-bold">카카오</h4>
          </a>
        </div>
      </Modal>
    );
  } else {
    return (
      <Modal id={openId} openId={openId} className="w-full" position="center">
        <div className="flex">
          {isIphone ? (
            <button
              className="flex flex-1 flex-col items-center space-y-2"
              onClick={() => {
                const appStoreLink = 'http://itunes.apple.com/app/id311867728?mt=8';
                window.location.href = `nmap://navigation?dlat=${y}&dlng=${x}&dname=${destinationName}&appname=${origin}`;
                setTimeout(() => {
                  window.location.href = appStoreLink;
                }, 1000);
              }}
            >
              <img src={naverNaviLogo} className="w-14" />
              <h4 className="text-sm font-bold">네이버</h4>
            </button>
          ) : (
            <a
              // https://guide.ncloud-docs.com/docs/naveropenapiv3-maps-url-scheme-url-scheme
              href={
                isAndroidOrSamsungDevice
                  ? `intent://navigation?dlat=${y}&dlng=${x}&dname=${destinationName}&appname=${origin}#Intent;scheme=nmap;action=android.intent.action.VIEW;category=android.intent.category.BROWSABLE;package=com.nhn.android.nmap;end`
                  : `nmap://navigation?dlat=${y}&dlng=${x}&dname=${destinationName}&appname=${origin}}`
              }
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-1 flex-col items-center space-y-2"
            >
              <img src={naverNaviLogo} className="w-14" />
              <h4 className="text-sm font-bold">네이버</h4>
            </a>
          )}
          <a
            // https://apis.map.kakao.com/web/guide/#mapurl
            href={`https://map.kakao.com/link/to/${destinationName},${y},${x}`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-1 flex-col items-center space-y-2"
          >
            <img src={kakaoNaviLogo} className="w-14" />
            <h4 className="text-sm font-bold">카카오</h4>
          </a>
          <a
            // https://okky.kr/articles/712480
            href={`tmap://route?goalname=${destinationName}&goaly=${y}&goalx=${x}`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-1 flex-col items-center space-y-2"
          >
            <img src={tmapLogo} className="w-14" />
            <h4 className="text-sm font-bold">티맵</h4>
          </a>
        </div>
      </Modal>
    );
  }
};

export default NavigatorsModal;

// Import the QueryStatus type from the react-query package

import { Image } from '../media/media.interface';
import { Rebate } from '../rebate/rebate.interface';
import { User } from '../user/user.interface';

export type BugoMultiAgency<HeaderImage = Image, FooterImage = Image> = {
  headerImage?: HeaderImage; // 부고문자에 넣을 로고
  footerImage?: FooterImage; // 부고문자에 넣을 로고
  theme: string; // enum: default, dark, blue(프리드용)
  corpName: string;
  nickName: string;
  phoneNumber: string;
  address: string;
  frontUrl: string;
  regAlimTalkWebDomain: string;
  homepageUrl: string;
  kakaoAppJavascriptKey?: string;
  kakaoTemplateBugoButtonId?: string;
};

// Define the BugoBrand type as an object with various properties
export type BugoBrand<
  Users = string[],
  Workers = undefined,
  ShopOrders = undefined,
  Shops = undefined,
  HeaderImage = string,
  FooterImage = string,
  Rebate = string,
  FuneralHomeInfo = string,
  FuneralHeaderImage = string,
  FuneralFooterImage = string,
  FuneralRebate = string,
> = {
  _id: string;
  users: Users;
  workers: Workers;
  type: string; // enum: BugoFuneralHome, BugoAgency
  shopOrders: ShopOrders;
  shops: Shops; // 협업하는 꽃집
  bugoAgency?: // (nullable)
  {
    corpName: string;
    nickName: string;
    phoneNumber: string;
    address: string;
    frontUrl: string;
    regAlimTalkWebDomain: string;
    headerImage: HeaderImage; // 부고문자에 넣을 로고
    footerImage?: FooterImage; // 부고문자에 넣을 로고
    rebate: Rebate;
    theme: string; // enum: default, dark, blue(프리드용)
    regions: string[];
    teamTypes: string[];
    teamNames: string[];
    homepageUrl: string;
    kakaoAppJavascriptKey?: string;
    kakaoTemplateBugoButtonId?: string;
    shareRebateWithWorker?: boolean;
  };
  bugoAgencyList?: BugoMultiAgency<Image, Image>[]; // (nullable)
  bugoFuneralHome?: // (nullable)
  {
    funeralHomeInfo: FuneralHomeInfo; // 장례식장 물리적인 정보
    headerImage: FuneralHeaderImage; // 부고문자에 넣을 로고
    footerImage: FuneralFooterImage; // 부고문자에 넣을 로고
    rebate: FuneralRebate;
    theme: string; // enum: default, dark, blue(프리드용)
    homepageUrl: string;
  };
};

export type UserBugoBrand = BugoBrand<
  string[],
  undefined,
  undefined,
  undefined,
  Image,
  Image
>;

export type BugoBrandCreate = Omit<
  BugoBrand,
  '_id' | 'users' | 'workers' | 'shopOrders' | 'shops'
>;

export type BugoBrandUpdate<
  Users = string[],
  Workers = undefined,
  ShopOrders = undefined,
  Shops = undefined,
  HeaderImage = string,
  FooterImage = string,
  Rebate = string,
  FuneralHomeInfo = string,
  FuneralHeaderImage = string,
  FuneralFooterImage = string,
  FuneralRebate = string,
> = {
  _id: string;
  users?: Users;
  workers?: Workers;
  type?: string; // enum: BugoFuneralHome, BugoAgency
  shopOrders?: ShopOrders;
  shops?: Shops; // 협업하는 꽃집
  bugoAgency?: // (nullable)
  {
    corpName?: string;
    nickName?: string;
    phoneNumber?: string;
    address?: string;
    frontUrl?: string;
    regAlimTalkWebDomain?: string;
    headerImage?: HeaderImage; // 부고문자에 넣을 로고
    footerImage?: FooterImage; // 부고문자에 넣을 로고
    rebate?: Rebate;
    theme?: string; // enum: default, dark, blue(프리드용)
    regions?: string[];
    teamTypes?: string[];
    teamNames?: string[];
    homepageUrl?: string;
    kakaoAppJavascriptKey?: string;
    kakaoTemplateBugoButtonId?: string;
    shareRebateWithWorker?: boolean;
  };
  bugoFuneralHome?: // (nullable)
  {
    funeralHomeInfo?: FuneralHomeInfo; // 장례식장 물리적인 정보
    headerImage?: FuneralHeaderImage; // 부고문자에 넣을 로고
    footerImage?: FuneralFooterImage; // 부고문자에 넣을 로고
    rebate?: FuneralRebate;
    theme?: string; // enum: default, dark, blue(프리드용)
    homepageUrl?: string;
  };
};

export type BugoBrandData = BugoBrand<
  string[],
  User[],
  undefined,
  undefined,
  Image,
  Image,
  Rebate
>;

export enum BugoBrandType {
  BugoAgency = 'BugoAgency',
  BugoFuneralHome = 'BugoFuneralHome',
}

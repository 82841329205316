import { FC } from 'react';

import { deceasedNameString } from '@service/bugo/utils/bugoString.util';
import { BugoData } from '@shared/api/bugo/bugo.interface';
import { fileBucketUrl } from '@shared/utils/fileUtils';
import { formatFeventDateString } from '@shared/utils/formatDate';

interface Props {
  bugo: BugoData;
}

export const ObituaryHeader: FC<Props> = function ObituaryHeader({ bugo }: Props) {
  if (bugo.fevent.deceasedInfo.dpImage) {
    return (
      <div className="h-32 bg-gray-800 py-3 px-6">
        <div className="flex h-full gap-6">
          <img
            className="h-full rounded-sm bg-gradient-to-t from-gray-50 to-gray-400"
            src={fileBucketUrl(bugo.fevent.deceasedInfo.dpImage.url, 'w400')}
          ></img>
          <div className="flex-1 space-y-3 pt-1">
            <p className="batang text-left text-30 font-bold text-white">訃告</p>
            <div className="space-y-2 text-left text-white">
              <p className="text-19 font-bold leading-5">
                {deceasedNameString(
                  bugo.fevent.deceasedInfo.name,
                  bugo.fevent.deceasedInfo.sex,
                  bugo.fevent.deceasedInfo.age,
                  bugo.fevent.deceasedInfo.nameDetail,
                )}
              </p>
              {bugo.fevent.deceasedInfo.death?.date && (
                <p className="text-14 leading-5">
                  {formatFeventDateString(bugo.fevent.deceasedInfo.death, false)}&nbsp;
                  {bugo.fevent.deceasedInfo.bugoTerm
                    ? bugo.fevent.deceasedInfo.bugoTerm
                    : '별세'}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="h-32 space-y-3 bg-gray-800 pt-4">
        <p className="batang text-center text-30 font-bold text-white">訃告</p>
        <div className="space-y-2 text-center text-white">
          <p className="text-19 leading-5">
            {deceasedNameString(
              bugo.fevent.deceasedInfo.name,
              bugo.fevent.deceasedInfo.sex,
              bugo.fevent.deceasedInfo.age,
              bugo.fevent.deceasedInfo.nameDetail,
            )}
          </p>
          {bugo.fevent.deceasedInfo.death?.date && (
            <p className="text-14 leading-5">
              {formatFeventDateString(bugo.fevent.deceasedInfo.death, false)}&nbsp;
              {bugo.fevent.deceasedInfo.bugoTerm
                ? bugo.fevent.deceasedInfo.bugoTerm
                : '별세'}
            </p>
          )}
        </div>
      </div>
    );
  }
};

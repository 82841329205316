import { FC, useEffect, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import DateFilterSearcher from '@service/bugo/components/DateFilterSearcher';
import { ShopOrderAgencyAdmin } from '@shared/api/shopOrder/shopOrder.atom';
import { User } from '@shared/api/user/user.interface';
import CheckboxFilter, { CheckBoxFilter } from '@shared/components/CheckboxFilter';
import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { getAgencyWorkerTeamName } from '@shared/utils/agencyWorkerUtils';
import { formatDate } from '@shared/utils/formatDate';
import { mainBgColorGenerator } from '@shared/utils/mainColorGenerator';
import { Table, TableColumnsType } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import FuzzySearch from 'fuzzy-search';
import _ from 'lodash';

import WorkerDetailModalOpener from '../WorkerDetailModalOpener/WorkerDetailModalOpener';
import AgencyAdminShopOrderStatusBar from './AgencyAdminShopOrderStatusBar';

interface IProps {
  orderList: ShopOrderAgencyAdmin[];
  teamTypesFilter: CheckBoxFilter | null;
  searchValue: string;
}

type ShopOrderTableData = {
  id: string;
  user: Pick<User, '_id' | 'info' | 'bugoAgencyWorkerDetail'>;
  shopItemName: string;
  createdAt: Date | '';
  paymentAt: Date | '';
  priceRetail: number;
};

const AgencyAdminShopOrderListTableMobile: FC<IProps> = ({
  orderList,
  teamTypesFilter,
  searchValue,
}: IProps) => {
  const customTheme = useRecoilValue(customThemeAtom);

  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateRange, setDateRange] = useState<[any | null, any | null]>([null, null]);

  const [dateFilteredShopOrderList, setDateFilteredShopOrderList] =
    useState<ShopOrderAgencyAdmin[]>(orderList);

  //* 페이지네이션 안되있으면 이런애를 씌우면 일단 에러 안남
  const shopOrderPaid = useMemo(() => {
    return orderList;
  }, [orderList]);

  // TeamType Filtering
  const [selectedTeamTypes, setSelectedTeamTypes] = useState(teamTypesFilter);

  const teamTypesFilteredFeventList = useMemo(() => {
    if (selectedTeamTypes) {
      return dateFilteredShopOrderList.filter(
        (shopOrder) =>
          selectedTeamTypes[
            shopOrder.fevent?.user?.bugoAgencyWorkerDetail?.teamType ?? ''
          ],
      );
    } else {
      return dateFilteredShopOrderList;
    }
  }, [dateFilteredShopOrderList, selectedTeamTypes]);

  // Search
  const resultValue = useMemo(() => {
    const searcher = new FuzzySearch(teamTypesFilteredFeventList, [
      'fevent.registerNumber',
      'fevent.user.info.name',
      'fevent.user.bugoAgencyWorkerDetail.teamName',
      'fevent.user.bugoAgencyWorkerDetail.teamType',
      'fevent.user.bugoAgencyWorkerDetail.region',
      'orderDetail.shopItemEmbed.name',
    ]);
    return searcher.search(searchValue);
  }, [teamTypesFilteredFeventList, searchValue]);

  const onDateRangeChange: RangePickerProps['onChange'] = (dates) => {
    if (dates) {
      setDateRange(dates);
    } else {
      setDateRange([null, null]);
    }
  };

  //* dateRange 변경 적용
  useEffect(() => {
    const rangeFilteredList = shopOrderPaid.filter((shopOrder) => {
      if (dateRange[0] && dateRange[1]) {
        if (shopOrder.fevent.deceasedInfo.coffinOut.date) {
          const coffinOut = new Date(shopOrder.fevent.deceasedInfo.coffinOut.date);
          if (dateRange[0].toDate() <= coffinOut && dateRange[1].toDate() >= coffinOut) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    });
    setDateFilteredShopOrderList(rangeFilteredList);
  }, [dateRange, orderList, shopOrderPaid]);

  const tableData: ShopOrderTableData[] = useMemo(() => {
    return _.map(
      resultValue as ShopOrderAgencyAdmin[] | null,
      (shopOrder): ShopOrderTableData => {
        return {
          id: shopOrder._id,
          shopItemName: shopOrder.orderDetail.shopItemEmbed.name,
          user: shopOrder.fevent?.user,
          priceRetail: shopOrder.orderDetail.shopItemEmbed.priceRetail,
          createdAt: shopOrder.createdAt,
          paymentAt: shopOrder.paymentDetail.purchasedAt ?? '',
        };
      },
    );
  }, [resultValue]);

  const columns: TableColumnsType<ShopOrderTableData> = [
    {
      title: <div className="break-keep">주문일</div>,
      dataIndex: 'paymentAt',
      key: 'paymentAt',
      align: 'center',
      render: (value: Date) => {
        return (
          <p className="text-xs text-gray-600">
            {formatDate(value, {
              contains: { year: false, date: true },
              dateSeparater: '/',
            })}
          </p>
        );
      },
    },
    {
      width: '15%',
      title: '소속',
      dataIndex: 'user',
      key: 'teamType',
      align: 'center',
      render: (user: User) => {
        return <p className="break-keep text-xs">{getAgencyWorkerTeamName(user)}</p>;
      },
    },
    {
      width: '15%',
      title: '지도사',
      dataIndex: 'user',
      key: 'user',
      align: 'center',
      render: (user: User) => {
        return (
          <WorkerDetailModalOpener workerName={user.info.name} workerId={user._id} />
        );
      },
    },
    {
      title: '상품명',
      dataIndex: 'shopItemName',
      key: 'name',
      align: 'center',
      render: (name) => {
        return <p className="break-keep text-xs font-medium">{name}</p>;
      },
    },

    {
      title: <div className="break-keep">결제금액</div>,
      dataIndex: 'priceRetail',
      key: 'priceRetail',
      align: 'center',
      render: (value: number) => {
        return (
          <p className="break-keep text-xs text-gray-600">
            {(value / 10000).toFixed(1).toLocaleString()}만원
          </p>
        );
      },
    },
  ];

  return (
    <div className="space-y-2 px-2">
      {/* Date Range Picker */}
      <div className="flex items-center space-x-2 bg-opacity-60">
        {/* 시작일 */}
        <DateFilterSearcher
          dateRange={dateRange}
          onChange={onDateRangeChange}
          size="large"
        />
      </div>
      {/* 소속 필터 */}
      {selectedTeamTypes && (
        <div className="flex items-center border text-xs theme-bg-1 theme-border-1">
          <div
            className={`center-box self-stretch bg-opacity-40 px-2 font-bold ${mainBgColorGenerator(
              customTheme,
            )}`}
          >
            소속
          </div>
          <div className="flex flex-1 items-center justify-around">
            <CheckboxFilter
              filter={selectedTeamTypes}
              setFilter={(checked) => setSelectedTeamTypes(checked)}
            />
          </div>
        </div>
      )}
      <AgencyAdminShopOrderStatusBar shopOrderList={resultValue} />
      <Table
        className="w-full"
        columns={columns}
        dataSource={_.flatMapDeep([tableData])}
        pagination={{ position: ['bottomCenter'], pageSize: 15 }}
        rowKey={'id'}
        size={'small'}
        onHeaderRow={() => ({
          className: `text-xs font-bold bg-opacity-40 ${mainBgColorGenerator(
            customTheme,
          )}`,
        })}
      />
    </div>
  );
};

export default AgencyAdminShopOrderListTableMobile;

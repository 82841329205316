import { ComponentType, FC, SVGProps, useCallback } from 'react';

import { useRecoilValue } from 'recoil';

import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { UserCustomTheme } from '@shared/types';
import { ReactComponent as Back } from 'src/assets/icon/icon-back.svg';
import { ReactComponent as BigCheckBlue } from 'src/assets/icon/icon-big-check-blue.svg';
import { ReactComponent as BigCheck } from 'src/assets/icon/icon-big-check.svg';
import { ReactComponent as BugoMessageBlue } from 'src/assets/icon/icon-bugo-message-blue.svg';
import { ReactComponent as BugoMessage } from 'src/assets/icon/icon-bugo-message.svg';
import { ReactComponent as CalendarPlaceHolder } from 'src/assets/icon/icon-calendar-placeholder.svg';
import { ReactComponent as Calendar } from 'src/assets/icon/icon-calendar.svg';
import { ReactComponent as CaretDown } from 'src/assets/icon/icon-caret-down-solid.svg';
import { ReactComponent as CashAccountBlue } from 'src/assets/icon/icon-cashaccount-blue.svg';
import { ReactComponent as CashAccount } from 'src/assets/icon/icon-cashaccount.svg';
import { ReactComponent as CheckBox } from 'src/assets/icon/icon-check-box.svg';
import { ReactComponent as Check } from 'src/assets/icon/icon-check.svg';
import { ReactComponent as ChevronDown } from 'src/assets/icon/icon-chevron-down.svg';
import { ReactComponent as ChevronRight } from 'src/assets/icon/icon-chevron-right.svg';
import { ReactComponent as ChevronUp } from 'src/assets/icon/icon-chevron-up.svg';
import { ReactComponent as CircleMinusLight } from 'src/assets/icon/icon-circle-minus-light.svg';
import { ReactComponent as CirclePlusLight } from 'src/assets/icon/icon-circle-plus-light.svg';
import { ReactComponent as X } from 'src/assets/icon/icon-close.svg';
import { ReactComponent as CoffinCrossSolid } from 'src/assets/icon/icon-coffin-cross-solid.svg';
import { ReactComponent as Copy } from 'src/assets/icon/icon-copy.svg';
import { ReactComponent as Courtesy } from 'src/assets/icon/icon-courtesy.svg';
import { ReactComponent as CSBlue } from 'src/assets/icon/icon-cs-blue.svg';
import { ReactComponent as CS } from 'src/assets/icon/icon-cs.svg';
import { ReactComponent as CustomerCenterBlue } from 'src/assets/icon/icon-customer-center-blue.svg';
import { ReactComponent as CustomerCenter } from 'src/assets/icon/icon-customer-center.svg';
import { ReactComponent as DeliveryBlue } from 'src/assets/icon/icon-delivery-blue.svg';
import { ReactComponent as Delivery } from 'src/assets/icon/icon-delivery.svg';
import { ReactComponent as Download } from 'src/assets/icon/icon-download-light.svg';
import { ReactComponent as DPBlue } from 'src/assets/icon/icon-dp-blue.svg';
import { ReactComponent as DP } from 'src/assets/icon/icon-dp.svg';
import { ReactComponent as Ellipsis } from 'src/assets/icon/icon-ellipsis.svg';
import { ReactComponent as EmptyUser } from 'src/assets/icon/icon-empty-user.svg';
import { ReactComponent as ErrorBlue } from 'src/assets/icon/icon-error-blue.svg';
import { ReactComponent as Error } from 'src/assets/icon/icon-error.svg';
import { ReactComponent as Home } from 'src/assets/icon/icon-home.svg';
import { ReactComponent as LinkBlue } from 'src/assets/icon/icon-link-blue.svg';
import { ReactComponent as Link } from 'src/assets/icon/icon-link.svg';
import { ReactComponent as Loading } from 'src/assets/icon/icon-loading.svg';
import { ReactComponent as LocationCheck } from 'src/assets/icon/icon-location-check.svg';
import { ReactComponent as LocationDotSolid } from 'src/assets/icon/icon-location-dot-solid.svg';
import { ReactComponent as MagnifyingGlassRegular } from 'src/assets/icon/icon-magnifying-glass-regular.svg';
import { ReactComponent as MapLocation } from 'src/assets/icon/icon-map-location-dot-regular.svg';
import { ReactComponent as Menu } from 'src/assets/icon/icon-menu.svg';
import { ReactComponent as ModifyBlue } from 'src/assets/icon/icon-modify-blue.svg';
import { ReactComponent as Modify } from 'src/assets/icon/icon-modify.svg';
import { ReactComponent as MoneyBagBlue } from 'src/assets/icon/icon-money-bag-blue.svg';
import { ReactComponent as MoneyBag } from 'src/assets/icon/icon-money-bag.svg';
import { ReactComponent as OtherApps } from 'src/assets/icon/icon-other-apps.svg';
import { ReactComponent as PencilBlue } from 'src/assets/icon/icon-pencil-blue.svg';
import { ReactComponent as Pencil } from 'src/assets/icon/icon-pencil.svg';
import { ReactComponent as Phone } from 'src/assets/icon/icon-phone.svg';
import { ReactComponent as PlusSolid } from 'src/assets/icon/icon-plus-solid.svg';
import { ReactComponent as Question } from 'src/assets/icon/icon-question.svg';
import { ReactComponent as Receipt } from 'src/assets/icon/icon-receipt.svg';
import { ReactComponent as RegisterBlue } from 'src/assets/icon/icon-register-blue.svg';
import { ReactComponent as Register } from 'src/assets/icon/icon-register.svg';
import { ReactComponent as RotateRight } from 'src/assets/icon/icon-rotate-right-regular.svg';
import { ReactComponent as SendBlue } from 'src/assets/icon/icon-send-blue.svg';
import { ReactComponent as Send } from 'src/assets/icon/icon-send.svg';
import { ReactComponent as Setting } from 'src/assets/icon/icon-settings.svg';
import { ReactComponent as ShareKakao } from 'src/assets/icon/icon-share-kakao.svg';
import { ReactComponent as ShareMessage } from 'src/assets/icon/icon-share-message.svg';
import { ReactComponent as TaxiBus } from 'src/assets/icon/icon-taxi-bus-solid.svg';
import { ReactComponent as TimePlaceholder } from 'src/assets/icon/icon-time-placeholder.svg';
import { ReactComponent as Time } from 'src/assets/icon/icon-time.svg';
import { ReactComponent as UpFromBracket } from 'src/assets/icon/icon-up-from-bracket.svg';
import { ReactComponent as UploadImage } from 'src/assets/icon/icon-upload-image.svg';
import { ReactComponent as UserBlue } from 'src/assets/icon/icon-user-blue.svg';
import { ReactComponent as User } from 'src/assets/icon/icon-user.svg';
import { ReactComponent as View } from 'src/assets/icon/icon-view.svg';
import { ReactComponent as WreathBig } from 'src/assets/icon/icon-wreath-big.svg';
import { ReactComponent as WreathBlue } from 'src/assets/icon/icon-wreath-blue.svg';
import { ReactComponent as FlowerBlue } from 'src/assets/icon/icon-wreath-flower-blue.svg';
import { ReactComponent as Flower } from 'src/assets/icon/icon-wreath-flower.svg';
import { ReactComponent as Wreath } from 'src/assets/icon/icon-wreath.svg';

function withStroke<T extends SVGProps<SVGSVGElement>>(Icon: ComponentType<T>): FC<T> {
  // TODO: stroke-current 넣으면 stroke-none 적용 안됨.

  return ({ className = '', ...props }) => (
    <Icon className={`${className}`} {...(props as T)} />
  );
}

export enum IconName {
  BugoMessage = 'BugoMessage',
  Calendar = 'Calendar',
  CalendarPlaceHolder = 'CalendarPlaceHolder',
  ChevronDown = 'ChevronDown',
  ChevronRight = 'ChevronRight',
  ChevronUp = 'ChevronUp',
  X = 'X',
  DP = 'DP',
  Courtesy = 'Courtesy',
  EmptyUser = 'EmptyUser',
  Back = 'Back',
  Link = 'Link',
  Menu = 'Menu',
  Modify = 'Modify',
  MoneyBag = 'MoneyBag',
  OtherApps = 'OtherApps',
  Pencil = 'Pencil',
  Phone = 'Phone',
  Register = 'Register',
  Send = 'Send',
  ShareKakao = 'ShareKakao',
  ShareMessage = 'ShareMessage',
  Time = 'Time',
  User = 'User',
  View = 'View',
  Flower = 'Flower',
  Wreath = 'Wreath',
  CirclePlusLight = 'CirclePlusLight',
  CircleMinusLight = 'CircleMinusLight',
  TimePlaceholder = 'TimePlaceholder',
  Receipt = 'Receipt',
  BigCheck = 'BigCheck',
  Error = 'Error',
  MagnifyingGlassRegular = 'MagnifyingGlassRegular',
  CS = 'CS',
  Delivery = 'Delivery',
  CaretDown = 'CaretDown',
  Check = 'Check',
  CheckBox = 'CheckBox',
  PlusSolid = 'PlusSolid',
  CoffinCrossSolid = 'CoffinCrossSolid',
  LocationDotSolid = 'LocationDotSolid',
  WreathBig = 'WreathBig',
  Setting = 'Setting',
  Question = 'Question',
  Home = 'Home',
  UploadImage = 'UploadImage',
  UpFromBracket = 'UpFromBracket',
  Copy = 'Copy',
  Ellipsis = 'Ellipsis',
  LocationCheck = 'LocationCheck',
  Loading = 'Loading',
  CustomerCenter = 'CustomerCenter',
  CustomerCenterBlue = 'CustomerCenterBlue',
  RotateRight = 'RotateRight',
  CashAccount = 'CashAccount',
  CashAccountBlue = 'CashAccountBlue',
  MapLocation = 'MapLocation',
  TaxiBus = 'TaxiBus',
}

export const Icon = () => {
  const theme = useRecoilValue(customThemeAtom);

  const ThemeColorIcon = useCallback(
    (
      defaultIcon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined;
        }
      >,
      preedIcon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined;
        }
      >,
      darkIcon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & {
          title?: string | undefined;
        }
      >,
    ) => {
      switch (theme) {
        case UserCustomTheme.Default:
          return withStroke(defaultIcon);
        case UserCustomTheme.Preed:
          return withStroke(preedIcon);
        case UserCustomTheme.Dark:
          return withStroke(darkIcon);
        case UserCustomTheme.DaeMyung:
          // TODO: 대명 아이콘 적용!
          return withStroke(preedIcon);
        default:
          return withStroke(defaultIcon);
      }
    },
    [theme],
  );

  return {
    BugoMessage: ThemeColorIcon(BugoMessage, BugoMessageBlue, BugoMessage),
    Calendar: withStroke(Calendar),
    CalendarPlaceHolder: withStroke(CalendarPlaceHolder),
    ChevronDown: withStroke(ChevronDown),
    ChevronRight: withStroke(ChevronRight),
    ChevronUp: withStroke(ChevronUp),
    X: withStroke(X),
    DP: ThemeColorIcon(DP, DPBlue, DP),
    Courtesy: withStroke(Courtesy),
    EmptyUser: withStroke(EmptyUser),
    Back: withStroke(Back),
    Link: ThemeColorIcon(Link, LinkBlue, Link),
    Menu: withStroke(Menu),
    Modify: ThemeColorIcon(Modify, ModifyBlue, Modify),
    MoneyBag: ThemeColorIcon(MoneyBag, MoneyBagBlue, MoneyBag),
    OtherApps: withStroke(OtherApps),
    Pencil: ThemeColorIcon(Pencil, PencilBlue, Pencil),
    Phone: withStroke(Phone),
    Register: ThemeColorIcon(Register, RegisterBlue, Register),
    Send: ThemeColorIcon(Send, SendBlue, Send),
    ShareKakao: withStroke(ShareKakao),
    ShareMessage: withStroke(ShareMessage),
    Time: withStroke(Time),
    User: ThemeColorIcon(User, UserBlue, User),
    View: withStroke(View),
    Flower: ThemeColorIcon(Flower, FlowerBlue, Flower),
    Wreath: ThemeColorIcon(Wreath, WreathBlue, Wreath),
    CirclePlusLight: withStroke(CirclePlusLight),
    CircleMinusLight: withStroke(CircleMinusLight),
    TimePlaceholder: withStroke(TimePlaceholder),
    Receipt: withStroke(Receipt),
    BigCheck: ThemeColorIcon(BigCheck, BigCheckBlue, BigCheck),
    Error: ThemeColorIcon(Error, ErrorBlue, Error),
    MagnifyingGlassRegular: withStroke(MagnifyingGlassRegular),
    CS: ThemeColorIcon(CS, CSBlue, CS),
    Delivery: ThemeColorIcon(Delivery, DeliveryBlue, Delivery),
    CaretDown: withStroke(CaretDown),
    Check: withStroke(Check),
    CheckBox: withStroke(CheckBox),
    PlusSolid: withStroke(PlusSolid),
    CoffinCrossSolid: withStroke(CoffinCrossSolid),
    LocationDotSolid: withStroke(LocationDotSolid),
    WreathBig: withStroke(WreathBig),
    Setting: withStroke(Setting),
    Home: withStroke(Home),
    Question: withStroke(Question),
    UploadImage: withStroke(UploadImage),
    UpFromBracket: withStroke(UpFromBracket),
    Copy: withStroke(Copy),
    Ellipsis: withStroke(Ellipsis),
    LocationCheck: withStroke(LocationCheck),
    Loading: withStroke(Loading),
    CustomerCenter: ThemeColorIcon(CustomerCenter, CustomerCenterBlue, CustomerCenter),
    Download: ThemeColorIcon(Download, Download, Download),
    RotateRight: ThemeColorIcon(RotateRight, RotateRight, RotateRight),
    TaxiBus: ThemeColorIcon(TaxiBus, TaxiBus, TaxiBus),
    MapLocation: ThemeColorIcon(MapLocation, MapLocation, MapLocation),
    CashAccount: ThemeColorIcon(CashAccount, CashAccountBlue, CashAccount),
  };
};

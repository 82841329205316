import React, { FC, useEffect, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import DateFilterSearcher from '@service/bugo/components/DateFilterSearcher';
import { BugoStatRangeQueryEnum } from '@service/bugo/page/AgencyAdminBugoStatMobilePage';
import { FeventByBugoBrand } from '@shared/api/fevent/fevent.interface';
import { User } from '@shared/api/user/user.interface';
import CheckboxFilter, { CheckBoxFilter } from '@shared/components/CheckboxFilter';
import { TextField } from '@shared/components/TextField';
import { CheckBox } from '@shared/containers';
import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { getAgencyWorkerTeamName } from '@shared/utils/agencyWorkerUtils';
import { getDefaultDateFrom } from '@shared/utils/dateUtils';
import { formatDate } from '@shared/utils/formatDate';
import { getRoughRegion } from '@shared/utils/getRoughRegion';
import { mainBgColorGenerator } from '@shared/utils/mainColorGenerator';
import { Table, TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import FuzzySearch from 'fuzzy-search';
import _ from 'lodash';
import { CSVLink } from 'react-csv';

import BugoDetailModalOpener from '../BugoDetailModalOpener/BugoDetailModalOpener';
import WorkerDetailModalOpener from '../WorkerDetailModalOpener/WorkerDetailModalOpener';
import { AgencyAdminBugoStatusBar } from './AgencyAdminBugoStatusBar';

interface IProps {
  feventList: FeventByBugoBrand[];
  range: BugoStatRangeQueryEnum | null;
  teamTypesFilter: CheckBoxFilter | null;
  regionsFilter: CheckBoxFilter;
}

type FeventTableData = {
  id: string;
  index: number;
  user: User;
  teamName: string;
  region: string;
  funeralHome: string;
  createdAt: Date | '';
  coffinOut: Date | null;
  orderListLen: number;
  isDeleted: boolean;
  obituaryNotSent: boolean;
  name: string;
};

const onDateRangeChange = (
  dates: [any, any] | null,
  setValue: React.Dispatch<React.SetStateAction<[any, any]>>,
) => {
  if (dates) {
    setValue(dates);
  } else {
    setValue([null, null]);
  }
};

const AgencyAdminFeventTable: FC<IProps> = ({
  feventList,
  range,
  teamTypesFilter,
  regionsFilter,
}: IProps) => {
  const { isBugoAgencyBranchAdmin } = useAuth();
  const customTheme = useRecoilValue(customThemeAtom);
  const [searchValue, setSearchValue] = useState<string>('');

  const [dateRange, setDateRange] = useState<[any | null, any | null]>([null, null]);

  const [dateFilteredFeventList, setDateFilteredFeventList] = useState<
    FeventByBugoBrand[]
  >(feventList ?? []);

  const [noSentFiltered, setNoSentFiltered] = useState<boolean>(false);

  // TeamType Filtering
  const [selectedTeamTypes, setSelectedTeamTypes] = useState(teamTypesFilter);

  const teamTypesFilteredFeventList = useMemo(() => {
    if (selectedTeamTypes) {
      return dateFilteredFeventList.filter(
        (fevent) => selectedTeamTypes[fevent.user.bugoAgencyWorkerDetail?.teamType ?? ''],
      );
    } else return dateFilteredFeventList;
  }, [dateFilteredFeventList, selectedTeamTypes]);

  // Region Filtering
  const [selectedRegions, setSelectedRegions] = useState(regionsFilter);

  const regionsFilteredFeventList = useMemo(() => {
    return teamTypesFilteredFeventList.filter(
      (fevent) =>
        selectedRegions[
          getRoughRegion(
            (fevent.funeralHomeInfoEmbed ?? fevent.funeralHomeInfo)?.address?.split(
              ' ',
            )[0] ?? '',
          )
        ],
    );
  }, [teamTypesFilteredFeventList, selectedRegions]);

  // Search
  const searchFilteredValue = useMemo(() => {
    const searcher = new FuzzySearch(regionsFilteredFeventList, [
      'user.info.name',
      'funeralHomeInfo.name',
      'funeralHomeInfo.address',
      'funeralHomeInfoEmbed.name',
      'funeralHomeInfoEmbed.address',
      'registerNumber',
      'user.bugoAgencyWorkerDetail.teamType',
      'user.bugoAgencyWorkerDetail.teamName',
    ]);
    return searcher.search(searchValue);
  }, [regionsFilteredFeventList, searchValue]);

  const noSentFilteredValue = useMemo(() => {
    if (noSentFiltered) {
      return searchFilteredValue.filter((item) => {
        return (
          item.bugos.filter((bugo) => {
            return bugo.lastSentPhoneNumber;
          }).length <= 0
        );
      });
    } else {
      return searchFilteredValue;
    }
  }, [noSentFiltered, searchFilteredValue]);

  const tableData: FeventTableData[] = useMemo(() => {
    return _.map(noSentFilteredValue, (fevent, index) => {
      return {
        id: fevent._id,
        index: noSentFilteredValue.length - index,
        user: fevent.user,
        teamName: getAgencyWorkerTeamName(fevent.user),
        region:
          (fevent.funeralHomeInfoEmbed ?? fevent.funeralHomeInfo).address.split(' ')[0] ??
          '',
        funeralHome: fevent.funeralHomeInfoEmbed?.name ?? fevent.funeralHomeInfo.name,
        name: fevent.deceasedInfo.name,
        createdAt: fevent.createdAt,
        coffinOut: fevent.deceasedInfo.coffinOut?.date,
        orderListLen: fevent.shopOrders.length,
        isDeleted: fevent.meta.isDeleted,
        obituaryNotSent:
          fevent.bugos.filter((bugo) => {
            return bugo.lastSentPhoneNumber;
          }).length <= 0,
      };
    });
  }, [noSentFilteredValue]);

  const duplicatedDelteTableData = tableData.reduce((accu, prev) => {
    if (!prev.obituaryNotSent) {
      return accu.concat(prev);
    } else {
      const temp = tableData.find((item) => {
        const date1 = new Date(item.createdAt);
        const date2 = new Date(prev.createdAt);
        return (
          item.name === prev.name &&
          item.id !== prev.id &&
          date1.getDate() === date2.getDate() &&
          item.obituaryNotSent === false
        );
      });
      if (temp) {
        return accu;
      } else {
        return accu.concat(prev);
      }
    }
  }, [] as FeventTableData[]);

  const csvTableData = duplicatedDelteTableData.map((item) => {
    return {
      소속: item.teamName,
      소속지역: item.user.bugoAgencyWorkerDetail?.region,
      지도사: item.user.info.name,
      장례식장: item.funeralHome,
      고인명: item.name,
      등록일: item.createdAt
        ? formatDate(item.createdAt, {
            contains: {
              year: false,
              month: true,
              date: true,
              day: true,
              hours: false,
              minutes: false,
            },
          })
        : '',
      등록시간: item.createdAt
        ? formatDate(item.createdAt, {
            contains: {
              year: false,
              month: false,
              date: false,
              day: false,
              hours: true,
              minutes: true,
            },
          })
        : '',
      발인일: item.coffinOut,
      화환판매수량: item.orderListLen,
      발송여부: item.obituaryNotSent ? '미발송' : '발송',
      개인정보파기여부: item.isDeleted ? '파기완료' : '파기예정',
    };
  });

  const columns: TableColumnsType<FeventTableData> = isBugoAgencyBranchAdmin()
    ? [
        {
          width: '5%',
          title: <div className="break-keep">index</div>,
          dataIndex: 'index',
          key: 'index',
          align: 'center',
          render: (index: number) => {
            return <p className="break-keep text-xs">{index}</p>;
          },
        },
        {
          title: '소속',
          dataIndex: 'teamName',
          key: 'teamName',
          align: 'center',
          render: (teamName) => {
            return <p className="font-medium">{teamName}</p>;
          },
        },
        {
          title: '소속지역',
          dataIndex: 'user',
          key: 'userRegion',
          align: 'center',
          render: (user: User) => {
            return <p className="font-medium">{user.bugoAgencyWorkerDetail?.region}</p>;
          },
        },
        {
          title: () => {
            return (
              <div className="center-box gap-2">
                <p>지도사</p>
                <div className="center-box gap-1">
                  (
                  <CheckBox
                    checked={noSentFiltered}
                    onCheckedChange={(checked: boolean) => {
                      setNoSentFiltered(checked);
                    }}
                  ></CheckBox>
                  <p>미발송</p>)
                </div>
              </div>
            );
          },
          key: 'user',
          align: 'center',
          render: (value: FeventTableData) => {
            return (
              <WorkerDetailModalOpener
                workerName={value.user.info.name}
                workerId={value.user._id}
                obituaryNotSent={value.obituaryNotSent}
                fontSize={14}
              />
            );
          },
        },
        {
          title: '장례식장',
          dataIndex: 'funeralHome',
          key: 'funeralHome',
          align: 'center',
          render: (funeralHome) => {
            return <p className="font-medium">{funeralHome}</p>;
          },
        },
        {
          title: '등록일',
          dataIndex: 'createdAt',
          key: 'createdAt-Date',
          align: 'center',
          render: (value: Date | '') => {
            return (
              <p>
                {value
                  ? formatDate(value, {
                      contains: {
                        year: false,
                        month: true,
                        date: true,
                        day: true,
                        hours: false,
                        minutes: false,
                      },
                    })
                  : ''}
              </p>
            );
          },
        },
        {
          title: '등록시간',
          dataIndex: 'createdAt',
          key: 'createdAt',
          align: 'center',
          render: (value: Date | '') => {
            return (
              <div>
                <p>
                  {value
                    ? formatDate(value, {
                        contains: {
                          year: false,
                          month: false,
                          date: false,
                          day: false,
                          hours: true,
                          minutes: true,
                        },
                      })
                    : ''}
                </p>
              </div>
            );
          },
          defaultSortOrder: 'descend',
          sorter: {
            compare: (a, b) => {
              if (!a.createdAt || !b.createdAt) return 0;
              return a.createdAt.getTime() - b.createdAt.getTime();
            },
          },
        },
        {
          title: '화환판매수량',
          dataIndex: 'orderListLen',
          key: 'orderListLen',
          align: 'center',
          render: (value: number) => {
            // console.log(value);
            return <p className="text-gray-600">{value.toLocaleString()}개</p>;
          },
        },
        {
          title: '정보파기',
          key: 'isDeleted',
          dataIndex: 'isDeleted',
          align: 'center',
          render: (value: boolean) => {
            return <p className="text-gray-600">{value ? '완료' : '예정'}</p>;
          },
        },
      ]
    : [
        {
          width: '5%',
          title: <div className="break-keep">index</div>,
          dataIndex: 'index',
          key: 'index',
          align: 'center',
          render: (index: number) => {
            return <p className="break-keep text-xs">{index}</p>;
          },
        },
        {
          title: '소속',
          dataIndex: 'teamName',
          key: 'teamName',
          align: 'center',
          render: (teamName) => {
            return <p className="font-medium">{teamName}</p>;
          },
        },
        {
          title: '소속지역',
          dataIndex: 'user',
          key: 'userRegion',
          align: 'center',
          render: (user: User) => {
            return <p className="font-medium">{user.bugoAgencyWorkerDetail?.region}</p>;
          },
        },
        {
          title: () => {
            return (
              <div className="center-box gap-2">
                <p>지도사</p>
                <div className="center-box gap-1">
                  (
                  <CheckBox
                    checked={noSentFiltered}
                    onCheckedChange={(checked: boolean) => {
                      setNoSentFiltered(checked);
                    }}
                  ></CheckBox>
                  <p>미발송</p>)
                </div>
              </div>
            );
          },
          key: 'user',
          align: 'center',
          render: (value: FeventTableData) => {
            return (
              <WorkerDetailModalOpener
                workerName={value.user.info.name}
                workerId={value.user._id}
                obituaryNotSent={value.obituaryNotSent}
                fontSize={14}
              />
            );
          },
        },
        {
          title: '장례식장',
          dataIndex: 'funeralHome',
          key: 'funeralHome',
          align: 'center',
          render: (funeralHome) => {
            return <p className="font-medium">{funeralHome}</p>;
          },
        },
        {
          title: '등록일',
          dataIndex: 'createdAt',
          key: 'createdAt-Date',
          align: 'center',
          render: (value: Date | '') => {
            return (
              <p>
                {value
                  ? formatDate(value, {
                      contains: {
                        year: false,
                        month: true,
                        date: true,
                        day: true,
                        hours: false,
                        minutes: false,
                      },
                    })
                  : ''}
              </p>
            );
          },
        },
        {
          title: '등록시간',
          dataIndex: 'createdAt',
          key: 'createdAt',
          align: 'center',
          render: (value: Date | '') => {
            return (
              <div>
                <p>
                  {value
                    ? formatDate(value, {
                        contains: {
                          year: false,
                          month: false,
                          date: false,
                          day: false,
                          hours: true,
                          minutes: true,
                        },
                      })
                    : ''}
                </p>
              </div>
            );
          },
          defaultSortOrder: 'descend',
          sorter: {
            compare: (a, b) => {
              if (!a.createdAt || !b.createdAt) return 0;
              return a.createdAt.getTime() - b.createdAt.getTime();
            },
          },
        },
        {
          title: '화환판매수량',
          dataIndex: 'orderListLen',
          key: 'orderListLen',
          align: 'center',
          render: (value: number) => {
            // console.log(value);
            return <p className="text-gray-600">{value.toLocaleString()}개</p>;
          },
        },
        {
          title: '정보파기',
          key: 'isDeleted',
          dataIndex: 'isDeleted',
          align: 'center',
          render: (value: boolean) => {
            return <p className="text-gray-600">{value ? '완료' : '예정'}</p>;
          },
        },
        {
          title: '부고상세',
          key: 'bugo modal button',
          align: 'center',
          render: (value: FeventTableData) => {
            return (
              <div className="center-box">
                <BugoDetailModalOpener
                  fevent={feventList.find((fevent) => fevent._id === value.id) ?? null}
                />
              </div>
            );
          },
        },
      ];

  // default range 적용
  useEffect(() => {
    if (!range) return;

    const from = getDefaultDateFrom(range);
    const to = new Date();

    setDateRange([dayjs(from), dayjs(to)]);
  }, [range]);

  useEffect(() => {
    if (!feventList) return;
    // dateRange 적용.
    const rangeFilteredList = feventList.filter((fevent) => {
      if (!dateRange[0] || !dateRange[1]) return true;

      if (!fevent.createdAt) return false;
      const createdAt = new Date(fevent.createdAt);
      if (dateRange[0].toDate() <= createdAt && dateRange[1].toDate() >= createdAt) {
        return true;
      } else {
        return false;
      }
    });
    setDateFilteredFeventList(rangeFilteredList);
  }, [dateRange, feventList]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  };

  return (
    <div className="rounded-lg bg-white px-4">
      <div className="flex w-full items-center justify-between space-x-10 py-4">
        <div className="grid flex-1 grid-cols-2 gap-x-8 gap-y-4">
          <div className="flex items-center justify-start gap-2">
            <div className="flex-1 text-sm">
              <AgencyAdminBugoStatusBar feventList={searchFilteredValue} />
            </div>
            <div className="flex-1">
              <TextField
                className="w-full border-gray-500"
                type={'search'}
                onChange={onChangeHandler}
                placeholder="검색 상조 지도사명"
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <DateFilterSearcher
              dateRange={dateRange}
              onChange={(dates) => onDateRangeChange(dates, setDateRange)}
              size="large"
            />
            <CSVLink
              filename={'부고등록내역'}
              data={_.flatMapDeep([csvTableData])}
              className="center-box filled-gray-800 rounded-md px-4 py-2 font-bold"
            >
              <p className="text-white">엑셀 다운로드</p>
            </CSVLink>
          </div>
          {/* 소속 필터 */}
          {selectedTeamTypes && (
            <div className="flex items-center space-x-4 border text-sm theme-bg-1 theme-border-1">
              <div
                className={`center-box self-stretch bg-opacity-40 px-2 font-bold ${mainBgColorGenerator(
                  customTheme,
                )}`}
              >
                소속
              </div>
              <div className="flex flex-1 items-center justify-around space-x-4 py-1 pr-2">
                <CheckboxFilter
                  filter={selectedTeamTypes}
                  setFilter={(checked) => setSelectedTeamTypes(checked)}
                />
              </div>
            </div>
          )}
          {/* 지역 필터 */}
          <div className="flex items-center space-x-4 border text-sm theme-bg-1 theme-border-1">
            <div
              className={`center-box self-stretch bg-opacity-40 px-2 font-bold ${mainBgColorGenerator(
                customTheme,
              )}`}
            >
              지역
            </div>
            <div className="flex flex-1 items-center justify-around space-x-4 py-1 pr-2">
              <CheckboxFilter
                filter={selectedRegions}
                setFilter={(checked) => setSelectedRegions(checked)}
              />
            </div>
          </div>
        </div>
      </div>
      <Table
        className="w-full"
        columns={columns}
        dataSource={_.flatMapDeep([tableData])}
        pagination={{
          position: ['bottomCenter'],
          defaultPageSize: 15,
          showSizeChanger: true,
          pageSizeOptions: [10, 15, 20, 50, 100],
        }}
        rowKey={'id'}
        bordered
        size={'small'}
      />
    </div>
  );
};

export default AgencyAdminFeventTable;
